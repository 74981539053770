
import { useRoute, useRouter } from "vue-router"
import { computed, ref, watch, reactive } from "vue"
import OrganizationService from "@/services/OrganizationService"
import { useStore } from "vuex"
import AuthService from "@/services/AuthService"
import { state } from "@/utils/state"
import useAppUtils from "@/utils/app"
import TabsComponent from "../navigation/TabsComponent.vue"
import Chip from "primevue/chip"
import ChangeCompanyForm from "../companies/ChangeCompanyForm.vue"

export default {
  components: { TabsComponent, Chip, ChangeCompanyForm },
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const organization: any = reactive({})
    let orgCode = ref("")
    const organizationService = OrganizationService.getInstance()
    const organizationId = computed(() => route.params.organization_id as string)
    const { isMobile } = useAppUtils()
    const loadingOrg = ref(false)
    const changeCompanyDialogVisible = ref(false)
    const roles = Object.values(store.getters.getRoles)

    const canChangeCompany = computed(
      () =>
        roles.includes("SUPER_ADMIN") ||
        roles.includes("ADMIN") ||
        roles.includes("SUPER_SUPPORT") ||
        roles.includes("SUPER_DEV") ||
        roles.includes("SUPER_SALES")
    )

    const inDashboard = computed(() => route.name === "organizationDashboard")

    const tabItems = reactive([
      { label: "Dashboard", routeName: "organizationDashboard" },
      { label: "Contact", routeName: "organizationProfile" },
      {
        label: "Communication",
        routeName: "organizationCallHistory",
        attributes: { permissions: '["VSOL"]' },
      },
      { label: "Members", routeName: "organizationRoles" },
      { label: "Imaging", routeName: "orgImaging" },
      { label: "Dicom", routeName: "orgDicoms" },
      { label: "Customizations", routeName: "organizationCustomizations" },
      { label: "Templates", routeName: "templates" },
      { label: "Settings", routeName: "organizationSettings" },
      {
        label: "Stats",
        routeName: "organizationStats",
      },
      { label: "Sales", routeName: "organizationSales" },
    ])

    const subscriptionType = ref("")
    function determineSubscription() {
      switch (organization.subscription) {
        case "BRONZE":
          subscriptionType.value = "podium-bronze"
          break
        case "SILVER":
          subscriptionType.value = "podium-silver"
          break
        case "GOLD":
          subscriptionType.value = "podium-gold"
          break
        case "NONE":
          subscriptionType.value = "poll"
          break
      }
    }

    loadOrganization()

    function loadOrganization() {
      if (organizationId.value) {
        loadingOrg.value = true
        organizationService.getOrganization(organizationId.value).then((org) => {
          state.organization = org
          Object.assign(organization, org)
          orgCode.value = org.code
          checkForGoogleDrive()
          store.dispatch("setNewOrgHistory", organization)
          loadingOrg.value = false
          determineSubscription()
        })
        organizationService.putOrganizationToXavier(organizationId.value)
      }
      /*const walterpData = ref({
        organizationId: organizationId.value,
      })

      organizationService.addUserActivities(userId, walterpData.value)*/
    }

    // Listen for changes on this page (e.g. caused by organisation switcher)
    watch(
      () => organizationId.value,
      () => loadOrganization()
    )

    function onMigrate() {
      router.push({ name: "organizationMigration" })
    }

    const service = new AuthService()
    const loggingIn = ref(false)
    const VSOL_FRONTEND_BASE_URL = process.env.VUE_APP_VSOL_FRONTEND

    function onOpenVSOL6() {
      if (loggingIn.value) return

      loggingIn.value = true

      service.logInVsol(organizationId.value).then((response) => {
        if (response.status == 200) {
          let url =
            VSOL_FRONTEND_BASE_URL +
            "login/support?" +
            new URLSearchParams(response.data.tokens[0]).toString()
          // let routeData = VSOL6_FRONTEND + router.resolve({path: "/login", query: }).href;
          window.open(url, "_blank")
        } else {
          console.error("Login failed", response)
        }

        loggingIn.value = false
      })
    }

    let currentOrgFromList = reactive({ code: "", hash: "" })
    let orgList = reactive([
      { code: "A7N", hash: "13-GDu3n15obgd5GQQJJbRUYbCovb_Z20" },
      { code: "AAF", hash: "1-qb-3RBeGKWOmtnwK4NgnP1_ofbGp1RG" },
      { code: "AAS", hash: "1-wuBhVnG_cYn4lpRENe6VjK71dVqkMuU" },
      { code: "ABU", hash: "10FlOt7_m1x8UCBWcog0XD6_qlNXq5tvt" },
      { code: "ADE", hash: "1GtTfkmqxqzxWk4RzUzADhKm8zt8wONYu" },
      { code: "ADW", hash: "10PqwZykSKowjXgkC9lvxxemcPbTepiiS" },
      { code: "AHA", hash: "12n4WhpEP2YqDAgqL3GnV56aDdg0f4fV-" },
      { code: "aHEL", hash: "10gvfxJgRUV35YNilwUQ_dooOODTbDv4M" },
      { code: "ALC", hash: "113uG7l6qokGNAcJoYllxbFE20K7MAHtr" },
      { code: "ALJ", hash: "11kL3Mkp0UNZN7w4A-e22GqD6rc2UHg1Y" },
      { code: "aLLA", hash: "11DcKaRGbOiYEnGhFHqMxjyRbW7todiBu" },
      { code: "AMAN", hash: "1-LKl_8zEqmv3AbuTtjxwOrhISIM0rBJd" },
      { code: "APE", hash: "11rFsX32kQ1oLTkPFATFSW7fFfwhzkcHh" },
      { code: "aPLAN", hash: "123eXdOqvoOKSqFhugpgvR3A1TVfoaC0h" },
      { code: "ASM", hash: "10VjMdf66fZO1L1o3GSgWfXI0UjVPTYR-" },
      { code: "AST", hash: "169J2bXr4FMMryMj689iJ_dgV6PgyQ857" },
      { code: "AvdR", hash: "1-6kUEXEcIox07c3R3H0NPRUrlIjhwDaM" },
      { code: "AVS", hash: "12WctJu8Zxw8KTRabexZo0mJZef5eVa5v" },
      { code: "BAD", hash: "1-wHMl2gvX1UO8fVTxoxCAMrlbi0X1ZRC" },
      { code: "BAH", hash: "130tHcCvGMwipj1H8Hd2glH8pfp65YeZ1" },
      { code: "BBE", hash: "11ufSFAyEuEk9p_p7giIys97J5RWvLifK" },
      { code: "BBR", hash: "12jcJf2XIxmGnXyv8hyCkW6HvlhbzSQaw" },
      { code: "BDN", hash: "13SikLYanhJKd9nEU2vwxG8yUAgq0xDUg" },
      { code: "BEVA", hash: "1H-Rlb86lNM1j-5qFIoJ7QCKfNyf3bsuZ" },
      { code: "BFU", hash: "13kxcrUW8hsx9HuAb3bCIabTySaUTXGV1" },
      { code: "BGY", hash: "143K9Vc6ryT8KkW4zMb2Wy6nb-dyQQPeK" },
      { code: "BHEI", hash: "124N89BZlSq_EHLiR7cEdu1iGKyS4YK_z" },
      { code: "BIE", hash: "14YyEqpl69qM_IaEb57g2c43SNgwQiiQN" },
      { code: "BJA", hash: "14f_qREn5Li7O0hlzR4J679bEaALrVM0S" },
      { code: "BMI", hash: "15-m8VfUMwPcHPz83Z8MpQm8Mkcx3ITBi" },
      { code: "BMU", hash: "15MS1samX3wY27k25D1P9gWVDqzk-Zlsj" },
      { code: "BOS", hash: "15aw1A1IQTpH9XSGbZQbXjXd5nrtbxvPR" },
      { code: "BOSCH", hash: "15mvUWjq_A18NHJ7zVB7QVsCAHD1ZBm-0" },
      { code: "BOT", hash: "1624SUYuZ2kP3tR-d3gfM5gA85VUU7LlL" },
      { code: "BPI", hash: "16B5DvIfr0Y6oWEhdOVGF0-9M3K5NY1xr" },
      { code: "BRON", hash: "16OpJJ2FwypXwOJXLUk_uWLbC-dxDik_j" },
      { code: "BSI", hash: "12b1Cr7j0jFYSkk6l71IEx9gNBtfynjMe" },
      { code: "BSP", hash: "16ZqF1NTvycuJ8SWbUWXQj0Mu0kYsm2sf" },
      { code: "BTH", hash: "16mpQmPBdyWY_Xa9JD10dkhYH5yhKzppg" },
      { code: "BvdM", hash: "13ErbNerEX0XJ2QaXadac0fsBnfaKkYx7" },
      { code: "BVE", hash: "171Kfx5xQ5TvH2uhD1-FlcwrnZYQITVb5" },
      { code: "BVH", hash: "17GmEwASV-kikZ6rdCuTXMpGkUnlYzmPh" },
      { code: "BVM", hash: "17jLHbpEpmhsffLFpEIslm1QEP-TXvV9u" },
      { code: "BVP", hash: "186-do-ncZFVgfR0XH8bTxbhDu235ZoNZ" },
      { code: "BVW", hash: "18H0Wq0PhIf0qb-APjUalGsKtu8erDKp4" },
      { code: "CDR", hash: "18XU11FlBnr726sJic8KkKowXd07dtYZG" },
      { code: "CDS", hash: "1-rpuS_yY_SKJNiNHRG2qj0lneUHauJO4" },
      { code: "CDV", hash: "1tuMkZK3LfEajuLO4MM3_vx535GeL4oxZ" },
      { code: "CEL", hash: "18pD4ZjJxMsIDxKLC0ko1l2k4WpLo6V9Z" },
      { code: "CG5", hash: "19-NWd91y3aDw5_qn2Q_s_zDdjg_8ZF8N" },
      { code: "CKU", hash: "19IWHsiY4BGioZ0dAB3WoJOF5dN1d333b" },
      { code: "CLA", hash: "1-SrcnOuHUIPFrsf8u5NGMmY3prRs4zP3" },
      { code: "CLI", hash: "1-FKJZQ7-v3aHiFTc3eRbfkJpH0hGzcE7" },
      { code: "CME", hash: "10Hotdh8ELfFaHUtxFNuhrRps_HniqMs9" },
      { code: "CNOC", hash: "19Nryth3MIHF79J90TqzdCaS3sMBT6NWX" },
      { code: "CSE", hash: "12EGXecCEKGTCE5jdnGFh0wP1TEhcQEJC" },
      { code: "CST", hash: "19XHinfCpAJPsBGten9syqAW17AEiOE5w" },
      { code: "CVE", hash: "1ABcKOws0U91GNXXt_CYfbUWvBTd_xC6T" },
      { code: "CVT", hash: "1CwNc595TajGU3h2x86OftdQdBob9bsB1" },
      { code: "DAE", hash: "13C83EDKit2F39bBLKd2TUS49kI48ID_Z" },
      { code: "DGI", hash: "1-jBWCvOA6oEkF8jvOSVCIO5xWtRNdflH" },
      { code: "DHU", hash: "102Biof8b0KfZyG8P_G-ZBA2DaF3MA_Eg" },
      { code: "dILA", hash: "1-Z9aRLRqs35Uv70uZ3_uaL2tDwl_Ok6b" },
      { code: "DNA", hash: "13cXKkmKm1nzDcLQaLD9gX5mPFLiPsXhi" },
      { code: "EAG", hash: "11PT1YJ8-2jzTzPkNVvDO0cm_6NiydFIH" },
      { code: "EPO", hash: "10SUBpuYy8KL5y2eW0_8VAhobiMG8azhb" },
      { code: "ERO", hash: "13M1MEIRyk487LmjvU_kRXFg8nloKQDwz" },
      { code: "EZI", hash: "11NlFVdCAPpg4qNxZlAr5_5cDYiwhOYxy" },
      { code: "FBR", hash: "1-nXSEmJmzCyX9hwCIQM4XRySUXVJx_AR" },
      { code: "GDO", hash: "12Yq03V4Ozdg435lvPzdia9FRcpxGvQpv" },
      { code: "HGS", hash: "11IFJvlY3zHdPzERbUebc93cjDW5as032" },
      { code: "iBEL", hash: "15I4NKZRzHDKSE8yqatz2Sjj77KhmWUad" },
      { code: "iFOUR", hash: "10jjCBtVQmJtHqMqQUWiFm9YnFh50ZIC-" },
      { code: "IHA", hash: "1bRdygFI_HZbVZN9qvJRCOoAz3s_bQpYp" },
      { code: "IMV", hash: "1-DDfMBvnFim8aWVMNuT8Ob4nVa-d20aG" },
      { code: "IMV USA", hash: "1EsKVnRDbCVzA9yOxgiroulEa5wNIJevI" },
      { code: "IVA", hash: "1deLjcNnrtRYIR-LCMuwdKhXJ__q8oOyB" },
      { code: "IVDB", hash: "1d6ppGM1v1BYegJNAEIV5CZwoCChoZUjQ" },
      { code: "JDA", hash: "1G3O67n5AbxTLpGD0RSMMET9TYxMNClTr" },
      { code: "JG", hash: "1wxuEM8WvmUQNJPvCtmhjiV1vHYDGN37T" },
      { code: "JGI", hash: "10XkZgsMEdb8e44Gy9XOPFZ25rqX7TtP3" },
      { code: "JGR", hash: "11PukWAynWk7y04GhJrYPqd0qv_06LLLL" },
      { code: "JME", hash: "1BQJCsatvbu9JJrVkpoUEUCdUcHuVSQwQ" },
      { code: "JNU", hash: "14AMxCPDJDGlDUbv-oJHAgmhchHFdfwSS" },
      { code: "JOHANNA", hash: "1HFYqDblvnVrSUcx_MksZoUppBMRH2Sfd" },
      { code: "JRT", hash: "1owCzTzkA9gQwNuuMmL__1tzksUMoBywg" },
      { code: "JTH", hash: "1-2ElPz_KR8Z7DLicKXpaKGmSuZ7GEHGm" },
      { code: "JVE", hash: "13sZyQtOM4DVrFeJOzaHTuN6Y2rv2kyO3" },
      { code: "JWA", hash: "1bliMtKjhmbnTaRmLhPust8OfUe5z6kv7" },
      { code: "KDN", hash: "1-Z0TjZ-HYq9dq62I7Mt7Ge29kiHUOHJS" },
      { code: "KROM", hash: "11aTNvTZWzYxREuxwwJJHcU8rEEZoCc5n" },
      { code: "KROON", hash: "1HTKoAqV3Ioa0yIvsoDc08PM1-aHOzjlG" },
      { code: "KTI", hash: "1He5RpFWmvuG0HWtUH0aYXnpYv2EazL4X" },
      { code: "KVB", hash: "13K5-MQqgs2RR-KBEUYB5Z-SPIgRHcQBn" },
      { code: "KVE", hash: "13jJc4fSOZFnHT3Ym9MWd-A7B2KSGckGd" },
      { code: "KVZ", hash: "102cJ_WHUGcY0CTJnjzd6NnhQwcGKQk8S" },
      { code: "LDN", hash: "1vpAah3bOlLV1nX7uvziS-ms55LsAiieP" },
      { code: "LEU", hash: "12h3zInOL5OqCWHDLVx_y5QUaW-xaHCdu" },
      { code: "LING", hash: "1HhihHeKYediekXI0TV7yWpM1lUZHqxNG" },
      { code: "LJA", hash: "1-CEXqfWSHKuUmkvEUfWlpqhnzNM8YcG3" },
      { code: "LKS", hash: "10xwl0nVPZKGge_iFTdR5t8dVAOZ93wud" },
      { code: "LMO", hash: "1-KnpnovDx7Hdu0dcjDxQHHpjwr4gcRfk" },
      { code: "LNA", hash: "1J0LlaSzYWlWej6Yqth3koHOnDNg8GoTK" },
      { code: "LPA", hash: "1f-xojpnP08lf8tiK90duhEf7NTczegqk" },
      { code: "LPE", hash: "13kkGUB7GG6W-MEuEq0GEA8AwU4O91npg" },
      { code: "LPLA", hash: "148WlSabQBmTh2KHcMFrXZpS2PthottnU" },
      { code: "LTR", hash: "1HxcrkcMLyPNus0Min1QYjxj40USegnVR" },
      { code: "MCL", hash: "11yiR5b06s4KY189iuabeiaD7uci_zgNW" },
      { code: "MDC", hash: "11YwrY90TDNudc5T8GCZ9sNJvq3T5cGcK" },
      { code: "MFA", hash: "10QL2-gqll7VSKJ4jzq5rE92ikwoQDTfv" },
      { code: "MIL", hash: "1Jb9YAfsIjSgyj0fbQT6-Q_KGfac1a6Nw" },
      { code: "MLA", hash: "10qTCP0mLP7YEAtbSVH7UPbmVC8gGsQNO" },
      { code: "MLS", hash: "13luDsDbztP8R6zbxsGaczhqgq90m1a9n" },
      { code: "MOER", hash: "1uq1_KdNmLMm7Ywn3bi0-xYopXI2vZ6uM" },
      { code: "MSD", hash: "1-5xDxeL3iekr5PNLCVoygw2Tjzg3mTVR" },
      { code: "MTI", hash: "1-sXIG2ToqyqiA1BUTPrsy676guY8HWHZ" },
      { code: "OLA", hash: "1-XaUXVEmojv_akuvPEU0jWCsEtQFnzKR" },
      { code: "OPOV", hash: "12npgl8zqm-rlLV9SqoOxorc1s7HaCLF_" },
      { code: "PST", hash: "1-Hnx02SKMRMoJ_tdCylmraH0pS5_c0Yh" },
      { code: "RHA", hash: "1VMHVTcgLa1qVv95IzuplX2QOzAFM63z9" },
      { code: "ROB", hash: "1-jNwpDbcE4H-iBbj0mvLyLIWo-hdMC91" },
      { code: "ROS", hash: "1-I-rF6D_P4Ep7gPT4KtoKTiVt7hOfPpQ" },
      { code: "RSI", hash: "13K1u0bS-XYsoyWTyyAv2OMVsNI1Wm3tY" },
      { code: "SBT", hash: "1-SfisMYvK5VdHJaqAQiDL8CpEEhwzIPi" },
      { code: "SCO", hash: "14RrzDR08zOo8TuG8vgeM7_osFsF9P0RI" },
      { code: "SDO", hash: "13ziJFmQE089lSvGIJBaOwgBpFLY0Psyr" },
      { code: "SDU", hash: "11A8HQfeNgfFo9B2Svs5szTiIl4oXId_Y" },
      { code: "seMAU", hash: "1sLGKeuXz6nJyL8ddzO_aomG-k-TLNAV1" },
      { code: "SEP", hash: "1BUEIL8D11-NXHcLNilroRHS2Vh6ns-dl" },
      { code: "SKR", hash: "1tk2Mm0A7g1-3ovLrfDsUxrRpz4J2mFyf" },
      { code: "sIJKA", hash: "1GIH09xVBJU7WEgrSKsDowZ9ydFXSPhGS" },
      { code: "SMI", hash: "11aa6TMxz1DhzFGOS5F0f_2GrTkcaXYCI" },
      { code: "SPH", hash: "17Nffac2cXRg7TGuUlxAuAslJtpHLeTVG" },
      { code: "SST", hash: "11Z4XvKWU5twnW0GsQUiPRf4Q-wWOJfa9" },
      { code: "SUZ", hash: "12TPCrijIkC8O8F6ro6rnVGltgqgxBshv" },
      { code: "TVR", hash: "1IFj7Sm452MmFq48Dag3ZS-vsyGe8InSw" },
      { code: "UAR", hash: "1JD2PFNV_rebi7-47EKTKFljT6dT4Y_E2" },
      { code: "WAL14", hash: "1gif4nnEYYvlC-7qz2tu12tkaFvc1S6RA" },
      { code: "WDI", hash: "12AKUg32_a-mQgNbBSCRGtayPnxlHYc_l" },
      { code: "WEB05", hash: "1-UylZPbNydOdnTJ_z_Tdn2VuM2vRMcZx" },
      { code: "WJA", hash: "1IJX3k27JdSHQPG8tRUFj5LzV3e6RNwMd" },
      { code: "WOL", hash: "12wBWFb9KLhxmn0loaGRDyGzRgkKQMJH2" },
      { code: "WVE", hash: "144jZhOGcTp9z78gGfoENZ19HxQdCdn51" },
    ])

    let hasGoogleDrive = ref(false)

    function checkForGoogleDrive() {
      const orgWithDrive = orgList.find((org: any) => orgCode.value === org.code)
      if (orgWithDrive) {
        hasGoogleDrive.value = true
        Object.assign(currentOrgFromList, orgWithDrive)
      } else {
        hasGoogleDrive.value = false
        Object.assign(currentOrgFromList, { code: "", hash: "" })
      }
    }

    function openGoogleDriveOrgMap() {
      if (!hasGoogleDrive.value) return
      let url = "https://drive.google.com/drive/folders/"
      url += currentOrgFromList.hash

      window.open(url, "_target")
    }

    function reload() {
      changeCompanyDialogVisible.value = false
      loadOrganization()
    }

    return {
      tabItems,
      organization,
      loadOrganization,
      onMigrate,
      loggingIn,
      onOpenVSOL6,
      isMobile,
      loadingOrg,
      openGoogleDriveOrgMap,
      hasGoogleDrive,
      inDashboard,
      subscriptionType,
      changeCompanyDialogVisible,
      organizationService,
      reload,
      canChangeCompany,
    }
  },
}
