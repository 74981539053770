import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "org-header" }
const _hoisted_3 = { class: "org-header-title" }
const _hoisted_4 = ["title"]
const _hoisted_5 = { class: "org-title-card" }
const _hoisted_6 = {
  id: "org-title",
  class: "flex flex-wrap align-items-center"
}
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "org-quick-info" }
const _hoisted_9 = { class: "mt-1 icon-text" }
const _hoisted_10 = { class: "ml-1" }
const _hoisted_11 = { class: "mt-1 icon-text" }
const _hoisted_12 = { class: "ml-1" }
const _hoisted_13 = { class: "mt-1 icon-text" }
const _hoisted_14 = { class: "ml-1" }
const _hoisted_15 = { class: "org-action-buttons-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mdicon = _resolveComponent("mdicon")!
  const _component_Chip = _resolveComponent("Chip")!
  const _component_TabsComponent = _resolveComponent("TabsComponent")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_ChangeCompanyForm = _resolveComponent("ChangeCompanyForm")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($setup.loadingOrg)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, "loading"))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["org-container", { 'overflow-hidden': !$setup.isMobile, 'h-full': $setup.inDashboard }])
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              ($setup.organization.company)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(["company-title-card", { clickable: $setup.canChangeCompany }]),
                    title: 'Company: ' + $setup.organization.company.name,
                    onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.canChangeCompany ? ($setup.changeCompanyDialogVisible = true) : null))
                  }, [
                    _createElementVNode("h1", null, _toDisplayString($setup.organization.company.code), 1)
                  ], 10, _hoisted_4))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("h1", _hoisted_6, [
                  _createVNode(_component_mdicon, {
                    name: "domain",
                    class: "mr-2"
                  }),
                  _createTextVNode(" " + _toDisplayString($setup.organization.code), 1),
                  ($setup.organization.name)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_7, " - " + _toDisplayString($setup.organization.name), 1))
                    : _createCommentVNode("", true)
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_Chip, { class: "chip" }, {
                default: _withCtx(() => [
                  _createVNode(_component_mdicon, {
                    class: "icon-circle",
                    size: 25,
                    name: "account-voice"
                  }),
                  _createElementVNode("span", _hoisted_9, [
                    _createElementVNode("label", _hoisted_10, _toDisplayString($setup.organization.language), 1)
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_Chip, { class: "chip" }, {
                default: _withCtx(() => [
                  _createVNode(_component_mdicon, {
                    class: "icon-circle",
                    size: 25,
                    name: "office-building"
                  }),
                  _createElementVNode("span", _hoisted_11, [
                    _createElementVNode("label", _hoisted_12, _toDisplayString($setup.organization.organizationType), 1)
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_Chip, { class: "chip" }, {
                default: _withCtx(() => [
                  _createVNode(_component_mdicon, {
                    class: _normalizeClass(["icon-circle", {
              gold: $setup.organization.subscription === 'GOLD',
              silver: $setup.organization.subscription === 'SILVER',
              bronze: $setup.organization.subscription === 'BRONZE',
            }]),
                    size: 25,
                    name: $setup.subscriptionType
                  }, null, 8, ["class", "name"]),
                  _createElementVNode("span", _hoisted_13, [
                    _createElementVNode("label", _hoisted_14, _toDisplayString($setup.organization.subscription), 1)
                  ])
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("div", {
                class: _normalizeClass(["cloud-button", { disabled: $setup.loggingIn }]),
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($setup.onOpenVSOL6 && $setup.onOpenVSOL6(...args))),
                title: "VSOL6 Cloud"
              }, [
                _createVNode(_component_mdicon, { name: "openInNew" })
              ], 2),
              _createElementVNode("div", {
                class: _normalizeClass(["google-drive-button", { disabled: !$setup.hasGoogleDrive }]),
                onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => ($setup.openGoogleDriveOrgMap && $setup.openGoogleDriveOrgMap(...args))),
                title: "Google Drive"
              }, [
                _createVNode(_component_mdicon, { name: "folder-google-drive" })
              ], 2)
            ])
          ]),
          _createVNode(_component_TabsComponent, { items: $setup.tabItems }, null, 8, ["items"]),
          _createVNode(_component_router_view, {
            organization: $setup.organization,
            onValueChanged: $setup.loadOrganization
          }, null, 8, ["organization", "onValueChanged"])
        ], 2)),
    _createVNode(_component_Dialog, {
      visible: $setup.changeCompanyDialogVisible,
      "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => (($setup.changeCompanyDialogVisible) = $event)),
      header: "Change Company",
      dismissableMask: true,
      draggable: false,
      modal: true
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ChangeCompanyForm, {
          service: $setup.organizationService,
          id: $setup.organization?.id,
          "previous-company-id": $setup.organization?.company?.id,
          onReload: $setup.reload
        }, null, 8, ["service", "id", "previous-company-id", "onReload"])
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}